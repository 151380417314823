import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string | number
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

export default function CustomTable<T extends { [key: string]: any }>({
  onRowClick,
  orderBy = "id",
  data,
  columns,
  removeCellBorder = false,
  removeTableHeader = false,
}: {
  data: T[];
  onRowClick: (data: T, column?: GridColDef<any, any, any>) => void;
  orderBy: string;
  dataKey: string;
  removeTableHeader?: boolean;
  removeCellBorder?: boolean;
  columns: GridColDef[];
}) {
  const [order, setOrder] = React.useState<Order>("asc");

  const [selected, setSelected] = React.useState<number>();
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: string | number) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
    const { t } = useTranslation();
    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell, index) => (
            <TableCell
              key={`${headCell.field}-${index}`}
              padding={"normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
                className="text-[1.25rem] font-bold "
              >
                {String(headCell.headerName)}
                {/* {toTitleCase(String(headCell.headerName))} */}
                {orderBy === headCell.field ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? t("CustomTable.sorted-dsc")
                      : t("CustomTable.sorted-asc")}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string | number
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
  };

  return (
    <Box sx={{ width: "100%", height: "100%", zIndex: 1100 }}>
      <Paper sx={{ width: "100%", mb: 2, height: "100%", boxShadow: "none" }}>
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table
            stickyHeader
            sx={{ minWidth: "fit-content" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            {!removeTableHeader && (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
            )}
            <TableBody>
              {stableSort(data, getComparator(order, orderBy)).map(
                (row, rIndex) => {
                  // const labelId = `enhanced-table-checkbox-${rIndex}`;

                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={`row-${rIndex}`}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSelected(rIndex)}
                      className={`${
                        selected === rIndex
                          ? "bg-blue-200"
                          : "hover:bg-[#eff6ffb6]"
                      }`}
                    >
                      {columns.map((column, cIndex) => {
                        let value: number | string | boolean;

                        if (column.renderCell) {
                          return (
                            <TableCell
                              onClick={() => {
                                onRowClick(row as T, column);
                              }}
                              key={`cell-${rIndex}-${cIndex}`}
                              className="banku"
                              sx={{
                                borderBottom: `${removeCellBorder && "none"}`,
                                flex: `${column.flex}`,
                              }}
                              width={column.width ?? "auto"}
                              align={column.align ?? "left"}
                            >
                              {column.renderCell({
                                row,
                              } as GridValueGetterParams<any>)}
                            </TableCell>
                          );
                        } else {
                          if (column.valueGetter) {
                            value = column.valueGetter({
                              row: {
                                [column.field]: row[column.field],
                              },
                            } as GridValueGetterParams<any>);
                          } else {
                            value = row[column.field];
                          }

                          return (
                            <TableCell
                              onClick={() => {
                                onRowClick(row as T, column);
                              }}
                              key={`cell-${rIndex}-${cIndex}`}
                              className="!text-lg"
                              align={column.align ?? "left"}
                              sx={{
                                borderBottom: `${removeCellBorder && "none"}`,
                                flex: `${column.flex}`,
                              }}
                              width={column.width ?? "auto"}
                            >
                              {typeof value === "boolean" ? (
                                <input
                                  type="checkbox"
                                  checked={value}
                                  disabled
                                ></input>
                              ) : (
                                value?.toString() ?? ""
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
