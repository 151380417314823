import { LocationCityOutlined } from "@mui/icons-material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Divider } from "@mui/material";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import Typography from "@mui/material/Typography";
import { MessagePayload, onMessage } from "firebase/messaging";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import AuthContext from "src/app/contexts/auth-context";
import { useAuth } from "src/app/features/auth/hooks/useAuth";
import { messaging } from "src/app/firebase/firebase";
import UserProfile from "src/app/pages/auth/components/UserProfile";
import DropDown from "src/app/pages/common/drop-down/drop-down";
import { DropdownItemSelectedParams } from "src/app/pages/common/drop-down/DropdownItemSelectedParams";
import { FCMNotificationTypeEnum } from "src/app/pages/common/types/notification-type-enum";
import { PageLoadingContext } from "src/app/routing/AppRoutes";
import {
  useGetLanguagesQuery,
  useGetPaymentIdByPaymentNumberLazyQuery,
  useGetUserNotificationsLazyQuery,
  useMarkNotificationAsReadMutation,
  useUpsertUserDefaultLanguagemutationMutation,
} from "src/generated/graphql";
import Notifier, {
  emptyNotification,
  NotificationMessage,
  NotificationType,
} from "../../../common/notifier/notifier";
import { ReactComponent as Karibu } from "../images/Karibu.svg";
import Notification from "./components/Notification";
import { NotificationCardProps } from "./components/NotificationCard";
import style from "./masterHeader.module.scss";

interface FCMNotificationData {
  notification_id: string;
  component_name: string;
  component_id: string;
  notification_type_id: string;
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export const MasterHeader = () => {
  const { t, i18n } = useTranslation();
  const userService = useContext(AuthContext);
  const navigate = useNavigate();
  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] =
    useState<NotificationMessage>();
  const [profileIsOpen, setProfileIsOpen] = useState(false);

  const pageLoadingContext = useContext(PageLoadingContext);

  const [getPaymentId, { data: paymentData, loading: gettingPaymentId }] =
    useGetPaymentIdByPaymentNumberLazyQuery();

  const [getNotifications, { data: notificationData }] =
    useGetUserNotificationsLazyQuery();
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation();
  const [fcmPayloadData, setFCMPayloadData] = useState<MessagePayload>();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<
    DropdownItemSelectedParams | undefined
  >();
  const { data: languagesData, loading: loadingDefaultLanguage } =
    useGetLanguagesQuery();

  const [initialSelectedLanguageIndex, setInitialSelectedLanguageIndex] =
    useState<number>();

  console.log("Initial", initialSelectedLanguageIndex);

  const [isOpen, setIsOpen] = useState(false);
  const [openNav, setOpenNav] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (selectedDropdownValue?.selectionProcess == "Manual")
      if (selectedDropdownValue?.selectedItem.length < 3) {
        i18n.changeLanguage(selectedDropdownValue?.selectedItem?.toLowerCase());
      } else {
        i18n.changeLanguage(
          languagesData?.languages.find(
            (lang) =>
              lang?.lang.toLocaleLowerCase() ==
              selectedDropdownValue?.selectedItem?.toLowerCase()
          )?.code
        );
      }
  }, [userService, i18n, selectedDropdownValue, languagesData?.languages]);

  useEffect(() => {
    if (userService?.authContextData?.currentUser?.language?.code) {
      i18n.changeLanguage(
        userService?.authContextData?.currentUser?.language?.code
      );
    }
  }, [i18n, userService?.authContextData?.currentUser?.language?.code]);

  useEffect(() => {
    if (
      userService?.authContextData?.currentUser?.language?.code &&
      (languagesData?.languages?.length ?? 0) > 0
    ) {
      for (let i = 0; i < (languagesData?.languages?.length ?? 0); i++) {
        const _language = languagesData?.languages[i];
        if (
          _language?.code ==
          userService?.authContextData?.currentUser?.language?.code
        ) {
          setInitialSelectedLanguageIndex(undefined);
          setTimeout(() => {
            setInitialSelectedLanguageIndex(i);
          }, 100);
          break;
        }
      }
    }
  }, [
    setInitialSelectedLanguageIndex,
    userService?.authContextData?.currentUser?.language?.code,
    languagesData?.languages,
  ]);

  const gotoDashboard = () => {
    navigate("/landing");
  };

  const onHideNotification = () => {
    setNotificationMessage(emptyNotification(notificationMessage));
    getNotifications();
  };
  const [notifications, setNotifications] = useState<NotificationCardProps[]>(
    []
  );
  const [isFcmNotification, setIsFcmNotification] = useState<boolean>();

  useEffect(() => {
    if (notificationData?.userNotifications?.nodes?.length) {
      let noticeData = notificationData?.userNotifications?.nodes?.map(
        (val) => {
          return {
            title: val?.notification?.title,
            message: val?.notification?.body,
            date: val?.createdAt,
            onClick: () => {
              setIsFcmNotification(false);
              if (
                val?.notification?.notificationTypeId ===
                Number(FCMNotificationTypeEnum.ManualPaymentRequest)
              ) {
                getPaymentId({
                  variables: {
                    paymentNumber: String(val?.notification?.componentId),
                  },
                });
              }
              if (
                val?.notification?.notificationTypeId ===
                Number(FCMNotificationTypeEnum.Quotation)
              ) {
                navigate("orders-details/" + val?.notification?.componentId);
              }
              markNotificationAsRead({
                variables: {
                  notificationId: Number(val?.notification?.id),
                },
              });
              getNotifications();
            },
          };
        }
      ) as NotificationCardProps[];
      setNotifications(noticeData);
    }
  }, [
    notificationData?.userNotifications?.nodes,
    markNotificationAsRead,
    getPaymentId,
    getNotifications,
    navigate,
  ]);

  useEffect(() => {
    if (!!gettingPaymentId) {
      pageLoadingContext.setPageLoading?.(true);
    }
    if (paymentData?.payments?.nodes?.at(0)?.id) {
      console.log({ gettingPaymentId });
      pageLoadingContext.setPageLoading?.(false);
    }
  }, [gettingPaymentId, pageLoadingContext, paymentData?.payments?.nodes]);

  const [upsertUserDefaultLanguagemutationMutation] =
    useUpsertUserDefaultLanguagemutationMutation();

  const onDropdownItemSelected = (
    itemSelectedParams: DropdownItemSelectedParams
  ) => {
    if (itemSelectedParams.selectionProcess == "Automatic") return;
    setSelectedDropdownValue(itemSelectedParams);
    let selectedLang;
    if (itemSelectedParams.selectedItem.length < 3)
      selectedLang = languagesData?.languages?.find(
        (val) => val.code === itemSelectedParams.selectedItem?.toLowerCase()
      );
    else
      selectedLang = languagesData?.languages?.find(
        (val) =>
          val.lang.toLowerCase() ===
          itemSelectedParams.selectedItem?.toLowerCase()
      );

    if (selectedLang && selectedLang.id !== undefined) {
      upsertUserDefaultLanguagemutationMutation({
        variables: {
          langId: { languageId: selectedLang.id },
        },
      });
    }
  };

  const FilterItems = useMemo<string[]>(() => {
    if (languagesData?.languages) {
      return languagesData?.languages?.map((value) =>
        value?.code?.toUpperCase()
      );
    }
    return [];
  }, [languagesData?.languages]);

  const FilterMobileItems = useMemo<string[]>(() => {
    if (languagesData?.languages) {
      return languagesData?.languages?.map((value) =>
        value?.lang?.toUpperCase()
      );
    }
    return [];
  }, [languagesData?.languages]);

  const { logout } = useAuth();

  onMessage(messaging, (payload) => {
    setIsFcmNotification(true);
    if (
      payload?.data?.notification_type_id ===
      FCMNotificationTypeEnum.ManualPaymentRequest
    ) {
      getPaymentId({
        variables: {
          paymentNumber: payload?.data?.component_id,
        },
      });
    }
    setFCMPayloadData(payload);
  });

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    if (width > 769) setOpenNav(false);
  }, [width]);

  useEffect(() => {
    if (paymentData?.payments?.nodes?.at(0)?.id && isFcmNotification) {
      setNotificationMessage({
        message: String(fcmPayloadData?.notification?.title),
        type: NotificationType.info,
      });
    }
    if (
      paymentData?.payments?.nodes?.at(0)?.id &&
      isFcmNotification === false
    ) {
      navigate(`manual-payments/${paymentData?.payments?.nodes?.at(0)?.id}`);
      setIsFcmNotification(undefined);
      getNotifications();
    }
  }, [
    paymentData?.payments?.nodes,
    fcmPayloadData?.notification?.title,
    navigate,
    getNotifications,
    isFcmNotification,
  ]);

  const handleNotificationPress = (data: FCMNotificationData) => {
    if (
      data.notification_type_id === FCMNotificationTypeEnum.ManualPaymentRequest
    ) {
      navigate(`manual-payments/${paymentData?.payments?.nodes?.at(0)?.id}`);
      markNotificationAsRead({
        variables: {
          notificationId: Number(data.notification_id),
        },
      });
      setIsFcmNotification(undefined);
    }
    if (data.notification_type_id === FCMNotificationTypeEnum.Quotation) {
      navigate("orders-details/" + data.component_id);
      markNotificationAsRead({
        variables: {
          notificationId: Number(data.notification_id),
        },
      });
      setIsFcmNotification(undefined);
    }
    getNotifications();
  };

  useEffect(() => {
    if (width <= 1026) setProfileIsOpen(false);
  }, [width]);

  return (
    <>
      {width <= 1026 ? (
        <>
          <div className="bg-white flex justify-between items-center w-full">
            <Toolbar>
              <Typography variant="h6" noWrap>
                <div className="brand">
                  <span onClick={gotoDashboard} style={{ cursor: "pointer" }}>
                    <Karibu width={139.38} height={40.05} />
                  </span>
                </div>
              </Typography>
            </Toolbar>
            <div className="flex gap-2 mr-5">
              <div className="relative">
                <button
                  className="relative z-5 block border-2 rounded-full p-2"
                  onClick={() => setOpenNav(!openNav)}
                >
                  <MenuIcon sx={{ fontSize: 20 }} />
                </button>
              </div>
              <div className="relative">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  onBlur={() => {
                    setTimeout(() => {
                      setIsOpen(false);
                    }, 400);
                  }}
                  disabled={!notificationData?.userNotifications?.nodes?.length}
                  className="relative z-5 block border-2 rounded-full p-2"
                >
                  <NotificationsOutlinedIcon sx={{ fontSize: 20 }} />
                  {!!notificationData?.userNotifications?.nodes?.length && (
                    <span className="absolute top-0 -right-2 inline-block px-2 py-1 text-xs font-bold leading-none text-white bg-yardBackgroundLight rounded-full">
                      {notificationData?.userNotifications?.nodes?.length}
                    </span>
                  )}
                </button>

                {isOpen && (
                  <div className="absolute right-0 w-[24vw] mt-2 bg-white rounded-lg border shadow-xl z-[12]">
                    <Notification notificationData={notifications} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Notifier
            notificationMessage={notificationMessage}
            onHideNotifier={onHideNotification}
            onClick={() => {
              handleNotificationPress(
                fcmPayloadData?.data as any as FCMNotificationData
              );
              onHideNotification();
            }}
          />
          {openNav && (
            <div className="w-[100vw] h-auto  absolute z-[22] mt-[64px] flex flex-row ">
              <div className="bg-[#716e6e7e] h-[100vh] w-[55vw] "></div>
              <div className="bg-[#716e6e7e] h-[100vh] w-[45vw] ">
                <div
                  className="relative right-0 h-full bg-white border shadow-xl p-3 flex flex-col"
                  onMouseLeave={() => {
                    setTimeout(() => {
                      setOpenNav(false);
                    }, 400);
                  }}
                >
                  <div className="rounded-full w-[80px] h-[80px] bg-[#009d936b] p-8 mb-6 mt-8 ml-4 items-center ">
                    <p className="text-[#009D93] font-semibold	text-5xl text-center">
                      {userService?.authContextData?.currentUser?.fullName
                        .split(" (")[0]
                        .split(", ")
                        .map((n) => n[0])
                        .reverse()
                        .join("")}
                    </p>
                  </div>
                  <div className=" ml-4 mb-4">
                    <div>
                      {userService?.authContextData?.currentUser?.fullName}
                    </div>
                    <div className="text-slate-400 text-sm my-1">
                      {userService?.authContextData?.currentUser?.email}
                    </div>
                    <div className="text-blue-800 font-semibold text-lg">
                      {userService?.authContextData?.currentUser?.role
                        ?.description
                        ? userService?.authContextData?.currentUser?.role
                            ?.description
                        : ""}
                    </div>
                  </div>
                  <Divider />
                  <div className=" ">
                    {!!(
                      languagesData &&
                      !isNaN(Number(initialSelectedLanguageIndex))
                    ) && (
                      <DropDown
                        className={""}
                        onItemSelected={onDropdownItemSelected}
                        searchList={FilterMobileItems}
                        initialSelectedIndex={initialSelectedLanguageIndex}
                        size="lg"
                        frontLogoType="language"
                      />
                    )}
                  </div>
                  <Divider />
                  <p className="mt-4 ml-4 text-[13px] mb-1  text-slate-400">
                    {t("MainHeader.menu")}
                  </p>
                  <ul
                    className={`flex ${style.mainNavigation} ${style.ul} flex-col mb-2 z-0`}
                  >
                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        end
                        to="/landing"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <HomeIcon />
                          <>{t("MainHeader.overview")}</>
                        </p>
                      </NavLink>
                    </li>
                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        to="/orders"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <ShoppingCartOutlinedIcon />
                          <>{t("MainHeader.orders")}</>
                        </p>
                      </NavLink>
                    </li>
                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        to="/payments"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <CreditCardOutlinedIcon />
                          <>{t("MainHeader.payments")}</>
                        </p>
                      </NavLink>
                    </li>
                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        to="/configurations"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <ListAltIcon />
                          <>{t("MainHeader.configurations")}</>
                        </p>
                      </NavLink>
                    </li>

                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        to="/reports"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <SettingsApplicationsOutlinedIcon />
                          <>{t("MainHeader.reports")}</>
                        </p>
                      </NavLink>
                    </li>
                    <li className="relative p-2">
                      <NavLink
                        className={({ isActive }) =>
                          `${isActive ? style.activealt : ""}`
                        }
                        to="/content-management"
                      >
                        <p className="flex items-center gap-3 text-xl">
                          <AdminPanelSettingsOutlinedIcon />
                          <>{t("MainHeader.content-management")}</>
                        </p>
                      </NavLink>
                    </li>
                  </ul>
                  <Divider />
                  <button
                    onClick={() => logout()}
                    className=" text-red-500 mt-4 ml-4 text-left flex gap-4 text-xl items-center"
                  >
                    <ExitToAppOutlinedIcon />
                    <p className=""> {t("modules.logout.logout")}</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`bg-white flex px-8 items-center ${
              width < 1100 ? " flex-col" : "justify-between"
            } `}
          >
            <div className="flex self-start items-center">
              <Toolbar>
                <Typography variant="h6" noWrap>
                  <div className="brand">
                    <span onClick={gotoDashboard} style={{ cursor: "pointer" }}>
                      <Karibu width={139.38} height={40.05} />
                    </span>
                  </div>
                </Typography>
              </Toolbar>
              <div className="masterHeader__middle ml-4 flex-1 min-w-0 z-0">
                <ul className={`${style.mainNavigation} ${style.ul} z-1`}>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? `${
                                width < 1250 || width >= 1440
                                  ? `${style.active}`
                                  : `${style.active} max-w-[148px] text-nowrap text-ellipsis overflow-hidden`
                              }`
                            : `${
                                width < 1250 || width >= 1440
                                  ? ""
                                  : "max-w-[148px] text-nowrap text-ellipsis overflow-hidden"
                              }`
                        }`
                      }
                      end
                      to="/landing"
                    >
                      {t("MainHeader.overview")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? style.active : ""}`
                      }
                      to="/orders"
                    >
                      {t("MainHeader.orders")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? style.active : ""}`
                      }
                      to="/payments"
                    >
                      {t("MainHeader.payments")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? style.active : ""}`
                      }
                      to="/configurations"
                    >
                      {t("MainHeader.configurations")}
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? style.active : ""}`
                      }
                      to="/reports"
                    >
                      {t("MainHeader.reports")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? `${
                                width < 1100 || width >= 1440
                                  ? `${style.active}`
                                  : `${style.active} max-w-[146px] text-nowrap text-ellipsis overflow-hidden`
                              }`
                            : `${
                                width < 1100 || width >= 1440
                                  ? ""
                                  : "max-w-[140px] text-nowrap text-ellipsis overflow-hidden"
                              }`
                        }`
                      }
                      to="/content-management"
                    >
                      {t("MainHeader.content-management")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex items-center self-end mb-[7px]">
              <div className="relative mr-3 xl:mr-12 flex items-center justify-center">
                <LocationCityOutlined fontSize="large" />
                <h2
                  className={`${
                    width < 1250 || width >= 1440
                      ? " ml-2 text-[13px] text-[#274867]"
                      : " ml-2 text-[13px] text-[#274867] w-[120px] text-nowrap text-ellipsis overflow-hidden"
                  }`}
                >
                  {
                    userService.authContextData?.currentUser?.location?.company
                      .name
                  }
                </h2>
              </div>

              <div className="relative">
                <button
                  onClick={() => setNotificationIsOpen(!notificationIsOpen)}
                  onBlur={() => {
                    setTimeout(() => {
                      setNotificationIsOpen(false);
                    }, 400);
                  }}
                  disabled={!notificationData?.userNotifications?.nodes?.length}
                  className="relative z-5 block border-2 rounded-full p-2"
                >
                  <NotificationsOutlinedIcon sx={{ fontSize: 20 }} />
                  {!!notificationData?.userNotifications?.nodes?.length && (
                    <span className="absolute top-0 -right-2 inline-block px-2 py-1 text-xs font-bold leading-none text-white bg-yardBackgroundLight rounded-full">
                      {notificationData?.userNotifications?.nodes?.length}
                    </span>
                  )}
                </button>
                {notificationIsOpen && (
                  <div className="absolute right-0 w-[20vw] mt-2 bg-white rounded-lg border shadow-xl z-[12]">
                    <Notification notificationData={notifications} />
                  </div>
                )}
              </div>
              <div className="relative">
                <button
                  onClick={() => setProfileIsOpen(!profileIsOpen)}
                  /* onBlur={() => {
                setTimeout(() => {
                setProfileIsOpen(false);
                }, 400);
                }} */
                  className="relative z-5 block ml-10 border-2 rounded-full p-2"
                >
                  <PermIdentityIcon sx={{ fontSize: 20 }} />
                </button>
                {profileIsOpen && (
                  <div className="absolute right-0 w-[30vw] xl:w-[25vw] 2xl:w-[20vw] mt-2 bg-white rounded-lg border shadow-xl z-[12]">
                    <UserProfile />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Notifier
            notificationMessage={notificationMessage}
            onHideNotifier={onHideNotification}
            onClick={() => {
              handleNotificationPress(
                fcmPayloadData?.data as any as FCMNotificationData
              );
              onHideNotification();
            }}
          />
        </>
      )}
    </>
  );
};
