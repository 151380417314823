import { useTranslation } from "react-i18next";
import { useAuth } from "src/app/features/auth/hooks/useAuth";
import { useContext, useEffect, useState } from "react";
import AuthContext from "src/app/contexts/auth-context";
import Translate from "@mui/icons-material/Translate";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  useGetLanguagesQuery,
  useUpsertUserDefaultLanguagemutationMutation,
} from "src/generated/graphql";
import Logout from "@mui/icons-material/Logout";
import i18n from "src/app/services/i18n/i18n";

const UserProfile = () => {
  const { t } = useTranslation();

  const { logout } = useAuth();

  const { authContextData } = useContext(AuthContext);

  const { data: languagesData, loading: loadingDefaultLanguage } =
    useGetLanguagesQuery();

  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState<{
    id: number;
    lang: string;
    code: string;
  }>();

  useEffect(() => {
    if (i18n.language && (languagesData?.languages?.length ?? 0) > 0) {
      for (let i = 0; i < (languagesData?.languages?.length ?? 0); i++) {
        const _language = languagesData?.languages[i];
        if (
          _language?.code == i18n.language &&
          selectedLanguageIndex === undefined
        ) {
          setSelectedLanguageIndex(undefined);
          setTimeout(() => {
            setSelectedLanguageIndex({
              id: _language.id,
              lang: _language.lang,
              code: _language.code,
            });
          }, 100);
          break;
        }
      }
    }
  }, [
    selectedLanguageIndex,
    setSelectedLanguageIndex,
    languagesData?.languages,
  ]);

  const [upsertUserDefaultLanguagemutationMutation] =
    useUpsertUserDefaultLanguagemutationMutation();

  const onLanguageListItemSelected = (itemId: number) => {
    let selectedLang = languagesData?.languages?.find(
      (val) => val.id === itemId
    );
    setSelectedLanguageIndex({
      id: itemId,
      lang: selectedLang?.lang ?? "",
      code: selectedLang?.code ?? "",
    });

    if (selectedLang && selectedLang.id !== undefined) {
      upsertUserDefaultLanguagemutationMutation({
        variables: {
          langId: { languageId: selectedLang.id },
        },
      });
    }
  };

  useEffect(() => {
    if (selectedLanguageIndex?.code != undefined) {
      i18n.changeLanguage(selectedLanguageIndex?.code);
    }
  }, [selectedLanguageIndex?.code]);

  console.log("selectedLanguageCode", selectedLanguageIndex?.code);

  return (
    <div
      className="bg-white"
      style={{ zIndex: 5000, display: "block", position: "relative" }}
    >
      <div className="flex flex-col items-center gap-2 py-4 mt-2">
        <div className="rounded-full w-[80px] h-[80px] bg-[#009D931A] p-10 ">
          <p className="text-[#009D93] font-medium text-3xl text-center">
            {authContextData?.currentUser?.fullName
              .split(" (")[0]
              .split(", ")
              .map((n) => n[0])
              .reverse()
              .join("")}
          </p>
        </div>
        <div>
          <p className="text-[#00274D] font-medium text-2xl px-10">
            {authContextData?.currentUser?.fullName}
          </p>
        </div>
        <div>
          <p className="text-[#54708C] font-normal	text-center w-[250px] xl:w-[250px] 2xl:w-[300px] text-nowrap text-ellipsis overflow-hidden">
            {authContextData?.currentUser?.email}
          </p>
        </div>
        <div>
          <p className="text-blue-600/75 font-bold px-10">
            {authContextData?.currentUser?.role?.description}
          </p>
        </div>
      </div>
      <div className="flex flex-col divide-y divide-slate-400/25 mt-3 border-t border-slate-400/25">
        <div>
          <Accordion sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<ChevronRight />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="flex flex-row gap-6 w-full items-center h-12">
                <div>
                  <p className="text-[#00274D] text-sm">
                    <Translate />
                  </p>
                </div>
                <p className="text-[#00274D] font-medium text-2xl">
                  {selectedLanguageIndex?.lang}
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col divide-y divide-slate-400/25 mt-0">
                {languagesData?.languages?.map((language) => (
                  <div
                    key={language.id}
                    onClick={() => onLanguageListItemSelected(language.id)}
                    className={`flex flex-row items-center w-full cursor-pointer h-12`}
                  >
                    <p className="text-[#00274D] font-normal w-full ml-4">
                      {language.lang}
                    </p>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="flex flex-row gap-6 w-full items-center h-20 pl-6"
          onClick={() => logout()}
        >
          <div>
            <p className="text-[#DA0901]">
              <Logout />
            </p>
          </div>
          <div>
            <p className="text-[#00274D] font-medium text-2xl">
              {t("modules.logout.logout")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
